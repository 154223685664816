import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navbar from './components/layout/Navbar';
import Login from './components/auth/Login';
import Register from './components/auth/Register';
import Dashboard from './components/dashboard/Dashboard';
import Home from './components/Home';
import Alert from './components/layout/Alert';
import NotFound from './components/layout/NotFound';

import NavbarLayout from './components/layout/NavbarLayout';
import PrivateRoute from './components/routing/PrivateRoute';


import { LOGOUT } from './actions/types';
// Redux
import store from './store';
import { Provider } from 'react-redux';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';

import './App.css';

const App = () => {
  useEffect(() => {
    // check for token in LS when app first runs
    if (localStorage.token) {
      // if there is a token set axios headers for all requests
      setAuthToken(localStorage.token);
    }
    // try to fetch a user, if no token or invalid token we
    // will get a 401 response from our API
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener('storage', () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);

  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route element={<NavbarLayout />}>
            <Route path="login" element={<Login />} />
            {/* <Route path="register" element={<Register />} /> */}
            <Route path="dashboard" element={<PrivateRoute component={Dashboard} />} />
          </Route>
          <Route path="/*" element={<NotFound />} />
        </Routes>
        <Alert />
      </Router>
    </Provider>
  );
};

export default App;
