import React from 'react';
import ReactDOM from 'react-dom';
import Web3 from 'web3'
import { Web3ReactProvider } from '@web3-react/core'
import reportWebVitals from './reportWebVitals';

import App from './App';

import './index.css';
import './assets/css/plugins/bootstrap.min.css';
import './assets/css/plugins/slick.css';
import './assets/css/plugins/animate.css';
import './assets/css/style.css';

function getLibrary(provider) {
    return new Web3(provider)
}

ReactDOM.render(
    <Web3ReactProvider getLibrary={getLibrary}>
        <App />
    </Web3ReactProvider>, 
    document.getElementById('root')
);
reportWebVitals();