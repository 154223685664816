import React, { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";

import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import Web3 from "web3";

import Modal from '../modal/Modal'; 

import "react-notifications/dist/react-notifications.css";
import {  NotificationContainer, NotificationManager } from "react-notifications";

import csv_icon from "../../assets/img/csv-icon.png"

// mossad
const RPC_URLS = {
  1: process.env.RPC_URL_1,
  2: process.env.RPC_URL_2,
};

const walletconnect =
  process.env.REACT_APP_ENVIRONMENT == "production"
    ? new WalletConnectConnector({
        rpc: { 137: RPC_URLS[1] },
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
        pollingInterval: 12000,
      })
    : new WalletConnectConnector({
        rpc: { 80001: RPC_URLS[2] },
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
        pollingInterval: 12000,
      });

const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const acceptedChains = ENVIRONMENT === "development" ? [80001] : [137];
const NFT_ADDRESS = ENVIRONMENT === "development" ? process.env.REACT_APP_TESTNET_ADDRESS : process.env.REACT_APP_MAINNET_ADDRESS;
const injected = new InjectedConnector({ supportedChainIds: acceptedChains });

const web3 = new Web3(Web3.givenProvider);
const contractABI = require("../../helper/abi.json");

const contract = new web3.eth.Contract(contractABI, NFT_ADDRESS);

const headers = [
  { label: "Address", key: "address" },
  { label: "Size", key: "size" },
  { label: "minted", key: "minted" }
];

const Dashboard = () => {

  const [data, setData] = useState([]);
  const { active, account, activate, deactivate } = useWeb3React();
  const [updating, setUpdating] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const showNotification = (message) => {  
    if (message.includes("User denied transaction")) {
      NotificationManager.warning("User denied transaction");
    }
    else if (message.includes("caller is not the owner")) {
      NotificationManager.error("Not allowed");
    }
    else if (message.includes("is missing role")) {
      NotificationManager.error("Disconnected");
    }
    else {
      // NotificationManager.error("Transaction is failed");
    }
  }  

  const onClickAdd = () => {
    if (data.length > 0 && data[data.length - 1].address === '') return;
    setData([...data, {address:'', size:'', minted: ''}]);
  }

  const connectMetamask = async() => {
    // console.log(account)
    // console.log(active)
    setIsOpen(false);

    await activate(injected)
    .then((res) => {      
      NotificationManager.success("Metamask Wallet is connected");  
    })
    .catch((ex) => {
      console.log(ex)
      NotificationManager.error("Error", ex, 2000);
    })    
  }

  const connectWalletConnect = async() => {
    setIsOpen(false);

    await activate(walletconnect, undefined, true)
    .then((res) => {
      NotificationManager.success("Connect Wallet is connected");
    })
    .catch((ex) => {
      NotificationManager.error("Error", ex, 2000);
    })
  }

  useEffect(() => {
    if (!active || !account) {
      return;
    }

    async function getMintStatus() {
      contract.methods
        .getMintStatus()
        .call({ from: account })
        .then((result) => {

          var newData = [];
          result.forEach(item => {
            newData.push({ address: item.wallet, size: item.size, minted: item.minted });
          });
          setData(newData);
        })
        .catch((err) => {
          if (err.message.includes("is missing role")) {
            setTimeout(() => disconnect(), 500);
          }
          showNotification(err.message);
          console.error("err", err);
        });
    }

    getMintStatus();

  }, [account]);

  const onClickUpdate = async() => {
    if (updating) return;

    let fieldError = 0, addressError = 0, addressDuplicateError = 0;
    data.forEach(item=>{
      item.address = item.address.trim();
      if (item.size === '' || item.address === '')
        fieldError ++;
      if (item.size < 0)
        fieldError ++;
      if (!web3.utils.isAddress(item.address))
        addressError ++;
    })

    if(fieldError > 0){
      NotificationManager.error('Invalid size.', 'Error', 2000);
      return ;
    }

    if(addressError > 0){
      NotificationManager.error('Please input valid address.', 'Error', 2000);
      return ;
    }

    for (let i = 0; i < data.length - 1; i++) {
      for (let j = i + 1; j < data.length; j++) {
        if (data[i].address == data[j].address) {
          addressDuplicateError ++;
          break;
        }
      }
    }    
    if(addressDuplicateError > 0){
      NotificationManager.error('Already existing address！', 'Error', 2000);
      return ;
    }

    // dispatch(createContact({data: data}, navigate));
    
    var contractData = [];

    data.forEach(item => {
      contractData.push({
        wallet: item.address,
        size: item.size,
        minted: 0        
      });
    })

    // const amountToWei = web3.utils.toWei(totalPrice.toString(), "ether");

    const { success, status } = await updateMintSize(account, contractData );
    if(success)
      NotificationManager.success('Success', status);
    else
      NotificationManager.error('', status, 5000);
  }

  const updateMintSize = async(account, contractData) => {
    setUpdating(true);

    return contract.methods
    .batchSetMintSize(contractData)
    .send({ from: account, maxPriorityFeePerGas: null, maxFeePerGas: null })
    .then((result) => {
      setUpdating(false);
      return {
        success: true,
        status:  "✅ Transaction is successful",
      };
    })
    .catch((err) => {
      console.log(err)
      setUpdating(false);
      return {
        success: false,
        status: "😥 Something went wrong: " + err.message,
      };
    });
  }

  const onClickRemove = (index) => {
    let tmp = [...data];
    tmp.splice(index, 1)
    setData(tmp)
  }

  const handleValueChange = (index, value) => {
    let tmp = [...data];
    tmp[index]['size'] = value;
    setData(tmp)
    // if (value > 0 && value >= Number(tmp[index]['minted'])) {
    // if (value > 0) {
    //   tmp[index]['size'] = value;
    //   setData(tmp)
    // }
    // else{
    //   NotificationManager.error("Must greater than minted number!");
    // }
  }
 
  const handleAddressChange = (index, value) => {
    let tmp = [...data];
    tmp[index]['address'] = value;
    setData(tmp)
  }

  const disconnect = async() => {
    await deactivate();
  }

  const todosList = data.map((item, index) => (
    <div className="justify-between align-items-center mb-2" key={index} style={{display: 'flex', flexWrap: 'wrap'}}>
      <span>{index + 1}.</span>
      <input name="address" type="text"  className="form-control col-md-8" value={item.address} onChange={(e)=>handleAddressChange(index, e.target.value)} />
      <div style={{display: 'flex'}}>
        <input name="size" type="number" className="form-control"  min="1" max="100" value={item.size} onChange={(e)=>handleValueChange(index, e.target.value)} />
        <input name="minted" type="number" className="form-control" style={{marginLeft: '50px'}} min="1" max="100" value={item.minted} disabled />
      </div>
      <div className="cursor-pointer" onClick={()=>onClickRemove(index)} >
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
          <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
        </svg>
      </div>
    </div>
  ));

  return (
    <section className="container">
      <div className="card mt-4">
        <h4 className="card-header">Issue number setting system</h4>
        <div className="card-body ml-4 mr-4">
          { todosList }
          <div className="justify-between mt-4" style={{display: 'flex'}}>
              <button className="btn text-white" style={{background: '#17a2b8'}} onClick={()=>onClickAdd()} >Add Address</button>
              {
                active ?
                <button className="cs-btn cs-btn_filed cs-accent_btn cs-modal_btn" onClick={()=>disconnect()}>Disconnect</button>
                :
                <button className="cs-btn cs-btn_filed cs-accent_btn cs-modal_btn" onClick={()=>setIsOpen(true)}>Connect</button>
              }
              <Modal open={isOpen} setIsOpen={setIsOpen} connectMetamask={connectMetamask} connectWalletConnect={connectWalletConnect} />
              <button
                  className="btn btn-primary" 
                  onClick={()=>onClickUpdate()} 
                  disabled={ !active || updating }
                >
                  {
                    updating ? 'Updating ...' : 'Save'
                  }
              </button>
              {
                active ?
                  <CSVLink data={data} headers={headers} filename={"mint.csv"}>
                    <img src={csv_icon} style={{width: '40px', height:'45px'}} alt="csv icon" />
                  </CSVLink>
                  :
                  <img src={csv_icon} style={{width: '40px', height:'45px'}} alt="csv icon" />
              }
          </div>
        </div>
      </div>
      
      <NotificationContainer />
    </section>
  );
};

export default Dashboard;