import { useEffect, useState } from "react";
import { useWeb3React } from "@web3-react/core";
import { InjectedConnector } from "@web3-react/injected-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import Web3 from "web3";

import LogoMini from '../assets/img/jamdao_logo_large.png';
import ZeroBlackLogo from '../assets/img/jamdao_logo.png';
import ZeroLogo from '../assets/img/jamdao_logo.png';
import ZeroLogoLg from '../assets/img/jamdao_logo_large.png';
import Zero from '../assets/img/jam.png';
import MetaMask from '../assets/img/metamask.svg'
// import CoinBase from '../assets/img/coinbase.svg'
// import TrustWallet from '../assets/img/trustwallet.svg'
// import WalletConnect from '../assets/img/walletconnect.svg'
import Back from '../assets/img/back.png';
import BBCGZ2 from  '../assets/img/jam_1_e.png';
import Bbcgzb from '../assets/img/jam_middle.png';
import Bbcgzw from '../assets/img/jam_middle.png'

import IconWallet from '../assets/img/icon_wallet.png';
import IconExchange from '../assets/img/icon_exchange.png';
import IconGames from '../assets/img/icon_game.png';
import IconBot from '../assets/img/icon_bot.png';
import IconSystem from '../assets/img/icon_system.png';
import IconCasino from '../assets/img/icon_casino.png';

import jamOne from '../assets/img/jam_1.png';
import jamThree from '../assets/img/jam_3.png';
import jamSeven from '../assets/img/jam_7.png';

import jamTitle1 from '../assets/img/image10.png';
import jamTitle3 from '../assets/img/image11.png';
import jamTitle7 from '../assets/img/image12.png';

// import roadmap from '../assets/img/roadmap.jpg';

import adv1 from '../assets/img/adv_1.png';
import adv2 from '../assets/img/adv_2.png';
import adv3 from '../assets/img/adv_3.png';
import adv4 from '../assets/img/adv_4.png';

import roadmapFirstTitle from '../assets/img/roadmapFirstTitle.png';
import roadmapBlueTitle from '../assets/img/roadmapBlueTitle.png';
import roadmapPinkTitle from '../assets/img/roadmapPinkTitle.png';
import roadmapYellowTitle from '../assets/img/roadmapYellowTitle.png';

import Icon_tw from '../assets/img/icon_tw.png';
import Icon_dc from '../assets/img/icon_dc.png';

import 'react-notifications/lib/notifications.css';
import { NotificationContainer, NotificationManager } from "react-notifications";

import Modal from "./modal/Modal";

const DISCORD_LINK = "https://discord.gg/CWPwZdqT6q";
const TWITTER_LINK = "https://twitter.com/jamdao_official";
const adv1_LINK = "https://cryptojumble.com/";
// const adv2_LINK = "https://coinmarketscore.io/";
const adv3_LINK = "https://www.bitcoreex.com/#/";
const adv4_LINK = "https://www.sagesse.io/app/#/exchange";

// mossad
const RPC_URLS = {
  1: process.env.RPC_URL_1,
  2: process.env.RPC_URL_2,
};

const walletconnect =
  process.env.REACT_APP_ENVIRONMENT == "production"
    ? new WalletConnectConnector({
        rpc: { 137: RPC_URLS[1] },
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
        pollingInterval: 12000,
      })
    : new WalletConnectConnector({
        rpc: { 80001: RPC_URLS[2] },
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
        pollingInterval: 12000,
      });


/** Do not destructure env variables */
const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
const MINT_PRICE = process.env.REACT_APP_MINT_PRICE;
const GAS_PRICE_MARGIN = 0.01;

// const acceptedChains = ENVIRONMENT === "development" ? [3, 4, 5, 42] : [1];
const acceptedChains = ENVIRONMENT === "development" ? [80001] : [137];
const NFT_ADDRESS = ENVIRONMENT === "development" ? process.env.REACT_APP_TESTNET_ADDRESS : process.env.REACT_APP_MAINNET_ADDRESS;
const injected = new InjectedConnector({ supportedChainIds: acceptedChains });

const web3 = new Web3(Web3.givenProvider);
const contractABI = require("../helper/abi.json");

const contract = new web3.eth.Contract(contractABI, NFT_ADDRESS);

// const NOT_CLAIMABLE = 0;
// const ALREADY_CLAIMED = 1;
// const CLAIMABLE = 2;
const MAX_MINT_COUNT = 50000;
const PRESALE_DEFAULT_COUNT = 1;
const PUBLICSALE_DEFAULT_COUNT = 0;
// const INITIAL_TOTAL_PRICE = 0.025;

const MINT_PRESALE_PRICE = MINT_PRICE;
const MINT_PUBLICSALE_PRICE = MINT_PRICE;

const TOTAL_SUPPLY_COUNT = 33;
const MINTED_COUNT = 0;

var freeMint = true;

const { MerkleTree } = require("merkletreejs");
const keccak256 = require("keccak256");
let whitelist = require("../helper/whitelist.json");

// hashing function for solidity keccak256
const hashNode = (account, amount) => {
  return Buffer.from(
    web3.utils
      .soliditySha3({ t: "address", v: account }, { t: "uint256", v: amount })
      .slice(2),
    "hex"
  );
};

const leaves = whitelist.map(addr => keccak256(addr));
const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true });

function Home() {
  const [minting, setMinting] = useState(false);
  const [count, setCount] = useState(PUBLICSALE_DEFAULT_COUNT);
  const [totalPrice, setTotalPrice] = useState(MINT_PRICE);
  const [mintedCount, setMintedCount] = useState(MINTED_COUNT);
  // const [whitelistClaimable, setWhitelistClaimable] = useState(NOT_CLAIMABLE);
  const [alreadyClaimed, setAlreadyClaimed] = useState(false);
  const [whitelistValid, setWhitelistValid] = useState(false);
  const [whitelistProof, setWhitelistProof] = useState([]);

  const [mintPrice, setMintPrice] = useState(MINT_PRICE);
  const [saleActive, setSaleActive] = useState(false);
  const [presaleActive, setPresaleActive] = useState(false);

  const { active, account, activate, deactivate } = useWeb3React();

  const [isOpen, setIsOpen] = useState(false);

  const showNotification = (message) => {  
    if (message.includes("User denied transaction")) {
      NotificationManager.warning("User denied transaction");
    }
    else if (message.includes("insufficient funds for gas")) {
      NotificationManager.error("Not enough MATIC to purchase NFTs!", "NFTを購入するためのMATICが不足しています。");
    }
    else if (message.includes("Exceeds max limit of this wallet")) {
      NotificationManager.warning("Exceeds max limit of this wallet");
    }
    else {
      NotificationManager.error("Transaction is failed");
    }
  }
  
  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = "./assets/js/jquery-3.6.0.min.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  // return () => {
  //     document.body.removeChild(script);
  //   }
  // }, []);

  const enterPresale = () => {
    async function checkIfClaimed() {
      contract.methods
        .claimed(account)
        .call({ from: account })
        .then((result) => {
          setAlreadyClaimed(result);
          if (result) {
            NotificationManager.info("Already claimed");
          }
        })
        .catch((err) => {
          setAlreadyClaimed(false);
        });
    }

    checkIfClaimed();

    // setCount(whitelist[account]);
    setCount(PRESALE_DEFAULT_COUNT);

    const leaf = keccak256(account);
    const proof = merkleTree.getHexProof(leaf);
    const root = merkleTree.getHexRoot();

    // console.log("ROOT", root);

    // just for front-end display convenience
    // proof will be validated in smart contract as well
    setWhitelistValid(merkleTree.verify(proof, leaf, root));

    setWhitelistProof(proof);
  }

  useEffect(()=>{
    if (!account) {
      return;
    }
    if (saleActive) {
      setMintPrice(MINT_PUBLICSALE_PRICE);
      setTotalPrice(MINT_PUBLICSALE_PRICE * PRESALE_DEFAULT_COUNT);
    }
    if (presaleActive) {
      setMintPrice(MINT_PRESALE_PRICE);
      setTotalPrice(MINT_PRESALE_PRICE * PRESALE_DEFAULT_COUNT);
    }
  }, [account, presaleActive, saleActive]);

  useEffect(() => {
    if (!active || !account) {
      return;
    }

    async function validatePresaleActive() {
      contract.methods
        .presaleActive()
        .call({ from: account })
        .then((result) => {
          setPresaleActive(!!result);
          if (result) {
            enterPresale();
          }
        })
        .catch((err) => {
          showNotification(err.message);
          console.error("err", err);
        });
    }

    validatePresaleActive();

  }, [account]);

  
  useEffect(() => {
    // if (!active || !account) {
    //   return;
    // }

    async function getMintedCount() {
      contract.methods
        .totalSupply()
        .call()
        .then((result) => {
          setMintedCount(result);
        })
        .catch((err) => {
          showNotification(err.message);
          console.error("err", err);
        });
    }
    // getMintedCount();
  }, []);

  useEffect(() => {
    if (!active || !account) {
      return;
    }

    async function validateSaleActive() {
      contract.methods
        .saleActive()
        .call({ from: account })
        .then((result) => {
          setSaleActive(!!result);
          if (result) {
            setPresaleActive(false);
            // setCount(PUBLICSALE_DEFAULT_COUNT);
            // setTotalPrice(mintPrice * PRESALE_DEFAULT_COUNT);
          }
        })
        .catch((err) => {
          showNotification(err.message);
          console.error("err", err);
        });
    }

    async function getMintSize() {
      contract.methods
        .mintableTokens(account)
        .call({ from: account })
        .then((result) => {
          let size = parseInt(result);
          setCount(size);
        })
        .catch((err) => {
          console.log('--------', err)
        });
    }

    getMintSize();

    validateSaleActive();

  }, [account]);

  useEffect(() => {
    if (!active || !account) {
      return;
    }

    async function validateMintPrice() {
      if (presaleActive) {
        contract.methods
        .preSalePrice()
        .call({ from: account })
        .then((result) => {
          const price = web3.utils.fromWei(result);

          setMintPrice(price);
          setTotalPrice(price * PRESALE_DEFAULT_COUNT);
        })
        .catch((err) => {
          showNotification(err.message);
          console.error("err", err);
        });
      }
      else if (saleActive) {
        contract.methods
        .price()
        .call({ from: account })
        .then((result) => {
          const price = web3.utils.fromWei(result);

          setMintPrice(price);
          setTotalPrice(price * PRESALE_DEFAULT_COUNT);

        })
        .catch((err) => {
          showNotification(err.message);
          console.error("err", err);
        });
      }
    }

    validateMintPrice();
  }, [account, presaleActive, saleActive]);

  function connect() {
    setIsOpen(true);
  }

  async function disconnect() {
    await deactivate();
  }

  async function connectMetamask() {
    setIsOpen(false)
    await activate(injected)
    .then((res) => {
      console.log(res)
      NotificationManager.success("Metamask Wallet is connected");
    })
    .catch((ex) => {
      console.log(ex)
      NotificationManager.error("Error", ex, 2000);
    })    
  }

  async function connectWalletConnect() {
    setIsOpen(false)
    await activate(walletconnect, undefined, true)
    .then((res) => {
      NotificationManager.success("Connect Wallet is connected");
    })
    .catch((ex) => {
      console.log('err ----------', ex)
    })
  }

  const preSale = async (account, proof, amount, amountToWei) => {
    return contract.methods
      .preSale(proof, amount)
      .send({ from: account, value: amountToWei,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null })
      .then((res) => {
        setMinting(false);
        NotificationManager.success("Mint is successful!");

        return {
          success: true,
          status:
            `✅ Check out your transaction on Etherscan: https://polygonscan.com/tx/` +
            res,
        };
      })
      .catch((err) => {
        setMinting(false);
        showNotification(err.message);

        return {
          success: false,
          status: "😥 Something went wrong: " + err.message,
        };
      });
  };
  
  const increaseGasLimit = (estimatedGasLimit) => {
    return estimatedGasLimit * 130 / 100; // increase by 30%
  };
  
  const mintNFTs = async (account, amount, amountToWei, gasAmount) => {
    let gasLimit = increaseGasLimit(Number(gasAmount)).toFixed(0);

    return contract.methods
      .mintNFTs(amount)
      .send({ from: account, value: amountToWei,
        gasLimit: gasLimit,
        maxPriorityFeePerGas: null,
        maxFeePerGas: null })
      .then(async (result) => {
        setMinting(false);

        NotificationManager.success("Mint is successful!");

        return {
          success: true,
          status:
            `✅ Check out your transaction on Etherscan: https://polygonscan.com/tx/` +
            result,
        };
      })
      .catch((err) => {
        setMinting(false);
        showNotification(err.message);

        return {
          success: false,
          status: "😥 Something went wrong: " + err.message,
        };
      });    
  };

  const onMint = async () => {
    if (saleActive) {
      onPublicMint();
    } else if (presaleActive) {
      onPreSale();
    }
  };

  const onPreSale = async () => {
    // const { success, status } = await preSale(account, whitelistProof, count);
    const price = (count * mintPrice).toString();
    const amountToWei = freeMint ? 0 : web3.utils.toWei(price, "ether");
    const checkPrice = ((freeMint ? 0 : count * mintPrice) + GAS_PRICE_MARGIN).toString();
    const checkToWei = web3.utils.toWei(checkPrice, "ether");

    setMinting(true);

    // Check ETH balance
    contract.methods.preSale(whitelistProof, count)
      .estimateGas({from: account, value: checkToWei})
      .then(function(gasAmount) {
        const { success, status } = preSale(account, whitelistProof, count, amountToWei);
      })
      .catch(function(error) {
        setMinting(false);
        NotificationManager.error("Not enough ether to purchase NFTs!", "NFTを購入するためのETHが不足しています。");
        return {
          success: false,
          status: "😥 Something went wrong: ",
        };
      });
  };

  const onPublicMint = async () => {
    if (count === 0) return;
    // const { success, status } = await mintNFTs(account, count);
    const price = (count * mintPrice).toString();
    const amountToWei = freeMint ? 0 : web3.utils.toWei(price, "ether");
    const checkPrice = ((freeMint ? 0 : count * mintPrice) + GAS_PRICE_MARGIN).toString();
    const checkToWei = web3.utils.toWei(checkPrice, "ether");

    setMinting(true);

    // Check ETH balance
    contract.methods.mintNFTs(count)
      .estimateGas({from: account, value: checkToWei})
      .then(function(gasAmount) {
        const { success, status } = mintNFTs(account, count, amountToWei, gasAmount);
      })
      .catch(function(error) {
        console.log(error)
        setMinting(false);
        showNotification(error.message);

        return {
          success: false,
          status: "😥 Something went wrong: ",
        };
      });
  };

  function handlePlusClick() {
    setCount(count >= MAX_MINT_COUNT ? MAX_MINT_COUNT : count + 1);
    setTotalPrice(count >= MAX_MINT_COUNT ? mintPrice * MAX_MINT_COUNT : (count+1) * mintPrice);
  };
  function handleMinusClick() {
    setCount(count <= 1 ? 1 : count - 1);
    setTotalPrice(count <= 1 ? mintPrice : ((count-1) * mintPrice));
  };
  const handleMaxClick = () => {
    setCount(MAX_MINT_COUNT);
    setTotalPrice(mintPrice * MAX_MINT_COUNT );
  };

  return (
    <div className="cs-dark">
      <div className="cs-preloader cs-white_bg cs-center">
        <div className="cs-preloader_in">
          <img src={LogoMini} alt="LogoMini" />
        </div>
      </div>
      <header className="cs-site_header cs-style1 cs-sticky-header cs-primary_color text-uppercase cs-white_bg">
        <div className="cs-main_header">
          <div className="container">
            <div className="cs-main_header_in">
              <div className="cs-main_header_left">
                <a className="cs-site_branding cs-accent_color" href="index.html">
                  <img src={ZeroBlackLogo} className="cs-hide_dark" />
                  <img src={ZeroLogo} className="cs-hide_white" />
                </a>
              </div>
              <div className="cs-main_header_center">
              </div>
              <div className="cs-main_header_right">
                <div>
                  <a href={TWITTER_LINK} target="_blank" rel="noreferrer" style={{marginRight: '20px'}}>
                    <img src={Icon_tw} className="social_icon mr-15" alt='icon tw'/>  
                  </a>
                  <a href={DISCORD_LINK} target="_blank" rel="noreferrer" style={{marginRight: '20px'}}>
                    <img src={Icon_dc} className="social_icon" alt='icon dc'/>  
                  </a>
                </div>
                <div className="cs-toolbox">
                  {/* <span className="cs-btn cs-btn_bordered cs-modal_btn" data-modal="mint">
                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth="2"><circle cx="12" cy="7" r="5"/><path strokeLinecap="round" strokeLinejoin="round" d="M17 22H5.266a2 2 0 0 1-1.985-2.248l.39-3.124A3 3 0 0 1 6.649 14H7m12-1v6m-3-3h6"/></g></svg>
                    <span>JOIN</span>
                  </span> */}
                  
                  { active ? 
                    (
                      <span className="cs-btn cs-btn_filed cs-accent_btn cs-modal_btn" onClick={disconnect} >
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M16 6H3.5v-.5l11-.88v.88H16V4c0-1.1-.891-1.872-1.979-1.717L3.98 3.717C2.891 3.873 2 4.9 2 6v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2zm-1.5 7.006a1.5 1.5 0 1 1 .001-3.001a1.5 1.5 0 0 1-.001 3.001z"/></svg>
                        <span>Disconnect</span>
                      </span>
                    )
                    :
                    (
                      <span className="cs-btn cs-btn_filed cs-accent_btn cs-modal_btn" onClick={connect}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 20 20"><path fill="currentColor" d="M16 6H3.5v-.5l11-.88v.88H16V4c0-1.1-.891-1.872-1.979-1.717L3.98 3.717C2.891 3.873 2 4.9 2 6v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8a2 2 0 0 0-2-2zm-1.5 7.006a1.5 1.5 0 1 1 .001-3.001a1.5 1.5 0 0 1-.001 3.001z"/></svg>
                        <span>Connect Wallet</span>
                      </span>
                    )
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="cs-height_80 cs-height_lg_160"></div>
      <section className="cs-hero cs-style1 cs-bg" data-src={Back} id="home">
        <div className="cs-dark_overlay"></div>
        <div className="container">
          <div className="cs-hero_text wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.2s">
            <div className="mintWrapper" style={{marginTop: '50px'}}>
              {/* <div id="detailWrapper">
                <ul id="supply" className="mintDetail">
                  <li className="title">TOTAL SUPPLY</li>
                  <li className="cnt">{TOTAL_SUPPLY_COUNT}</li>
                </ul>
                <span id="border"></span>
                <ul id="price" className="mintDetail">
                  <li className="title" style={{textAlign: 'left'}}>MINT PRICE</li>
                  <li className="cnt">{MINT_PRICE}ETH <span style={{fontSize:'0.5em'}}>+GAS</span></li>
                </ul>
              </div> */}
              {/* <h2 className="cs-hero_title text-uppercase cs-font_80 cs-font_50_sm cs-bold">JAMDAO</h2> */}
              <img src={ZeroLogoLg} className="cs-hide_white" />
              <h2 className="cs-hero_title text-uppercase cs-font_80 cs-font_50_sm">PASSPORT NFTs</h2>
              <br />
              <br />
              <h3 className="cs-hero_title text-uppercase cs-font_80 cs-font_50_sm">PRIVATE MINT</h3>
              <h5 className="cs-hero_title text-uppercase cs-font_42 cs-font_50_sm mb-30">FREE MINT</h5>
              {/* <p className="connectMsg">MINTED  <span>{mintedCount}</span> / {TOTAL_SUPPLY_COUNT}</p> */}
            </div>
            <div className="spinner_area mb-20">
              <input className="counter1 mr-5" value={count} data-max="5" data-min="0" name="count" readOnly />
              {/* <input className="mr-5" type="button" value="－" onClick={handleMinusClick} />
              <input className="mr-5" type="button" value="＋" onClick={handlePlusClick}  /> */}
            </div>
            {/* <h3 className="cs-hero_secondary_title cs-font_22 cs-font_18_sm text-uppercase">TOTAL ： {parseFloat(totalPrice).toFixed(4)} ETH</h3> */}

            <div className="cs-btn_group">
              {
                active ? 
                (
                  <button
                    className="cs-btn cs-btn_filed cs-accent_btn"
                    disabled={
                      minting ||
                      alreadyClaimed ||
                      (presaleActive && !whitelistValid) ||
                      (!presaleActive && !saleActive)
                    }
                    onClick={onMint}
                  >
                    { minting
                      ? "Waiting confirmation."
                      : presaleActive
                      ? "Pre Sale"
                      : "MINT"
                    }
                  </button>
                )
                :
                (
                  <button className="cs-btn cs-btn_filed cs-accent_btn" disabled={true}><span>MINT NOW</span></button>
                )
              }

              { 
                active ? 
                (
                  <button className="cs-btn cs-color1 cs-modal_btn" onClick={disconnect} >
                    <span>Disconnect</span>
                  </button>
                ) 
                : 
                (
                  <button className="cs-btn cs-color1 cs-modal_btn" onClick={connect} >
                    <span>Connect Wallet</span>
                  </button>
                )
              }
            </div>
            
            {/* <h3 className="cs-hero_subtitle cs-font_20 cs-font_16_sm cs-body_line_height">Max 5 NFTs Per Wallet. PRICE {MINT_PRICE} ETH <br />Mint is Live Until 23:59 on December 31, 2022</h3> */}
          </div>
          <div className="cs-hero_img wow fadeIn" data-wow-duration="1s" data-wow-delay="0.35s">
            <img src={Zero} style={{ maxWidth: '640px', width:'100%' }} />
          </div>
        </div>
      </section>
 
      <div className="cs-slider cs-style1 cs-gap-24 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.3s" style={{background: '#001d3c', border: '0px solid'}} >
        <div className="cs-slider_container" data-autoplay="1000" data-loop="1" data-speed="800" data-center="1" data-fade-slide="0"  data-slides-per-view="responsive" data-xs-slides="2" data-sm-slides="2" data-md-slides="3" data-lg-slides="4" data-add-slides="6">
          <div className="cs-slider_wrapper">
            <div className="cs-slide" >
                <img src={BBCGZ2} />
            </div>
            <div className="cs-slide">
                <img src={BBCGZ2} />
            </div>
            <div className="cs-slide">
                <img src={BBCGZ2} />
            </div>
            <div className="cs-slide">
                <img src={BBCGZ2} />
            </div>
            <div className="cs-slide">
                <img src={BBCGZ2} />
            </div>
            <div className="cs-slide">
                <img src={BBCGZ2} />
            </div>
            <div className="cs-slide">
                <img src={BBCGZ2} />
            </div>
            <div className="cs-slide">
                <img src={BBCGZ2} />
            </div>
            <div className="cs-slide">
                <img src={BBCGZ2} />
            </div>
            <div className="cs-slide">
                <img src={BBCGZ2} />
            </div>
            <div className="cs-slide">
                <img src={BBCGZ2} />
            </div>
            <div className="cs-slide">
                <img src={BBCGZ2} />
            </div>
          </div>
        </div>
      </div>
  
      <div className="cs-height_65 cs-height_lg_35"></div>
      <section id="about">
        <div className="container">
          <div className="row align-items-center flex-column-reverse-lg">
            <div className="col-lg-7 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
              <div className="cs-height_0 cs-height_lg_40"></div>
              <div className="cs-right_space_40">
                <div className="cs-seciton_heading cs-style1 text-uppercase">
                  {/* <h3 className="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">3,333 ANIMATED COWS</h3> */}
                  {/* <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">About Us</h2> */}
                </div>
                <div className="cs-height_20 cs-height_lg_20"></div>
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  JAM means "to mix and match", which in 
                  slang is also expressed as "like" and is also
                  used in the world of music as "JAM session".
                </p>
                <br />
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  JAM DAO aims to become a decentralized
                  autonomous organization (DAO) based on
                  the concept that participants vote for their
                  favorite projects and businesses, and grant
                  or share the rights and benefits to the
                  participating members.
                </p>
                {/* <p>Issued to commemorate the new journey of the Boom Boom Cows. Serves as a VIP Membership Ticket presented to BBC family. ( 33 item )</p> */}
                <div className="cs-height_25 cs-height_lg_15"></div>
                <div className="cs-btn_group">
                </div>
              </div>
            </div>
            <div className="col-lg-5 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
              <img src={Bbcgzb} className="cs-hide_dark" />
              <img src={Bbcgzw} className="cs-hide_white" />
            </div>
          </div>
        </div>
      </section>

      <div className="cs-height_65 cs-height_lg_35"></div>
      <section id="howto">
        <div className="container">
          <div className="cs-seciton_heading cs-style1 text-uppercase wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
            {/* <h3 className="cs-section_title cs-font_16 cs-font_14_sm cs-gradient_color">MINTING 4 STEPS</h3> */}
            <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">Our Businesses</h2>
          </div>
          <div className="cs-height_50 cs-height_lg_30"></div>
          <div className="row wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.4s">
            <div className="col-xl-1 col-md-1">
            </div>
            <div className="col-xl-11 col-md-11" style={{display:'flex', alignItems:'center'}}>
              <img src={IconWallet} style={{width:'50px', marginRight: '20px' , marginTop:'10px' }}/>
              <span className="cs-font_24 cs-line-height_45 ">Decentralized　Wallet</span>
            </div>
            <div className="col-xl-2 col-md-2">
            </div>
            <div className="col-xl-10 col-md-10" style={{display:'flex', alignItems:'center'}}>
              <img src={IconExchange} style={{width:'50px', marginRight: '20px' , marginTop:'10px'}}/>
              <span className="cs-font_24 cs-line-height_45">Exchange</span>
            </div>
            <div className="col-xl-3 col-md-3">
            </div>
            <div className="col-xl-9 col-md-9" style={{display:'flex', alignItems:'center'}}>
              <img src={IconGames} style={{width:'50px', marginRight: '20px' , marginTop:'10px'}}/>
              <span className="cs-font_24 cs-line-height_45">Blockchain Games</span>
            </div>
            <div className="col-xl-4 col-md-4">
            </div>
            <div className="col-xl-8 col-md-8" style={{display:'flex', alignItems:'center'}}>
              <img src={IconBot} style={{width:'50px', marginRight: '20px' , marginTop:'10px'}}/>
              <span className="cs-font_24 cs-line-height_45">Crypto Assets Management Bot</span>
            </div>
            <div className="col-xl-5 col-md-5">
            </div>
            <div className="col-xl-7 col-md-7" style={{display:'flex', alignItems:'center'}}>
              <img src={IconSystem} style={{width:'50px', marginRight: '20px' , marginTop:'10px'}}/>
              <span className="cs-font_24 cs-line-height_45">Data Analysis System</span>
            </div>
            <div className="col-xl-6 col-md-6">
            </div>
            <div className="col-xl-6 col-md-6" style={{display:'flex', alignItems:'center'}}>
              <img src={IconCasino} style={{width:'50px', marginRight: '20px' , marginTop:'10px'}}/>
              <span className="cs-font_24 cs-line-height_45">Online Casino</span>
            </div>
          </div>
          <div className="cs-height_40 cs-height_lg_40"></div>
          <p className="cs-font_24 cs-line-height_45 text-white font-bold" style={{marginTop:'10px'}}> 
            We select projects from each category, and the entire membership will vote on whether or not to start the project. After that, the right to sell the project and the profit to be gained will be earned, and part of the profits will be distributed to the DAO as a whole.
          </p>
        </div>
      </section>
      
      <div className="cs-height_75 cs-height_lg_45"></div>
      <div className="row cs-slider cs-style1 cs-gap-24 wow fadeIn advertise_part" data-wow-duration="1s" data-wow-delay="0.3s" style={{display:'flex', background: '#003e82', border: '0px solid'}} >
        <div className="col-xl-2 col-md-2 col-sm-12 m-auto text-center">
          <a href={adv1_LINK} target="_blank" rel="noreferrer">
            <img src={adv1} alt="CryptoJumble" style={{width:'110px'}} />
          </a>
        </div>
        <div className="col-xl-5 col-md-5 col-sm-12 m-auto text-center">
          <img src={adv2} style={{width:'80%'}} alt="CoinMarketScore"/>
        </div>
        <div className="col-xl-3 col-md-3 col-sm-12 m-auto text-center">
          <a href={adv3_LINK} target="_blank" rel="noreferrer">
            <img src={adv3} style={{width:'50%'}} alt="Bitcore"/>
          </a>
        </div>
        <div className="col-xl-2 col-md-2 col-sm-12 m-auto text-center">
          <a href={adv4_LINK} target="_blank" rel="noreferrer">
            <img src={adv4}  alt="Sagesse"/>
          </a>
        </div>
      </div>
      <section id="about" style={{background: '#7f7f7f', paddingTop: '20px'}}>
        <div className="container">
          <div className="col-lg-12 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
            <div className="cs-height_0 cs-height_lg_60"></div>
            <div className="cs-right_space_40">
              <div className="cs-seciton_heading cs-style1 text-uppercase">
                <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm roadmap_title" style={{marginLeft: '115px'}}>Roadmap</h2>
              </div>
              {/* <img src={roadmap} style={{width:'100%'}}/> */}
            </div>
          </div>
          <div className="row wow fadeInLeft middle_bg" data-wow-duration="1s" data-wow-delay="0.4s" style={{marginBottom: '30px', fontSize: '15px'}}>
            <div className="col-xl-3 col-md-3 text-center roadmap_one_title" style={{marginRight: '-115px', marginLeft: '115px'}}>
              <img src={roadmapFirstTitle} style={{minWidth:'150px', minHeight: '150px', zIndex: 4, position:'relative'}} />
              <div className="cs-font_12 text-white first-title-border-left" style={{borderLeft: '6px solid #7f59b0', marginLeft: '5px', textAlign: 'left', paddingLeft: '10px', height:'400px' }}>
                End of March, 2023<br />
                1st Recruitment will be Closed<br /><br />
                2,000 JAM DAO PASSPORT<br />
                NFTs will be solid<br /><br />
                Februdary, 2023<br />
                The Launch<br />
                of the CRIPT JAMBLE<br />
              </div>
            </div>
            <div className="col-xl-3 col-md-3 text-center roadmap_one_title" style={{marginLeft: '24px'}}>
              <img src={roadmapBlueTitle} style={{minWidth:'150px', minHeight: '150px', zIndex: 3, position:'relative'}} />
              <div className="cs-font_12 text-white" style={{borderLeft: '6px solid #63c5ea', marginLeft: '5px', textAlign: 'left', paddingLeft: '10px', height:'400px' }}>
                April 1, 2023<br />
                The Launch of the JAM DAO<br /><br />
                The Service will be Launched<br />
                ・Crypto Assets <br />
                Management Bot ”Saggese”<br />
                ・Exchange ”BitCore” <br />
                Voting for each <br /><br />
                Business Begins<br />
                （After the Spring）<br />
                ・Data Analysis System<br />
                ・Blockchain Games<br />
                ・Decentralized Wallet<br />
              </div>
            </div>
            <div className="col-xl-3 col-md-3 text-center roadmap_second_title" style={{marginLeft: '-90px'}}>
              <img src={roadmapPinkTitle} style={{minWidth:'150px', minHeight: '150px', zIndex: 2, position: 'relative'}} />
              <div className="cs-font_12 text-white" style={{borderLeft: '6px solid #ea4b8b', marginLeft: '5px', textAlign: 'left', paddingLeft: '10px', height:'400px' }}>
                End of 2023<br />
                2nd recruitment will be Closed<br />
                Earn Rewards <br />
                for each Business<br /><br />
                Each Business <br />
                will Start from the Selected<br />
                Business Candidates<br /><br />
                10,000 JAM DAO PASSPORT<br />
                NFTs will be Sold<br />
                Over 3,000 Participants <br />
                of the JAM DAO<br />
              </div>
            </div>
            <div className="col-xl-3 col-md-3 text-center roadmap_third_title" style={{marginLeft: '-90px'}}>
              <img src={roadmapYellowTitle} style={{minWidth:'150px', minHeight: '150px', zIndex: 1, position: 'relative'}} />
              <div className="cs-font_12 text-white" style={{borderLeft: '6px solid #fbc311', marginLeft: '5px', textAlign: 'left', paddingLeft: '10px', height:'400px' }}>
                End of 2024<br />
                3rd  recruitment will be Closed<br /><br />
                The Launch Controller Business<br /><br />
                Community Growth<br />
                Acceptance of Business Proposals <br />
                is Activated<br /><br />
                Over 10,000 Participants <br />
                of the JAM DAO<br />
                A Management Union <br />
                and an Audit Department <br />
                will Established<br />
                The Soundness of JAM DAO <br />
                will Ensured<br />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="cs-height_75 cs-height_lg_45"></div>
      <section id="about">
        <div className="container mt-40">
          <div className="row align-items-center flex-column-reverse-lg">
            <div className="col-lg-12 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
              <div className="cs-height_0 cs-height_lg_40"></div>
              <div className="cs-right_space_40">
                <div className="cs-seciton_heading cs-style1 text-uppercase">
                  <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">About JAM DAO PASSPORT NFT</h2>
                </div>
                <div className="cs-height_20 cs-height_lg_20 text-white"></div>
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  By participating in the JAM DAO, you will be entitled to various business revenues in the future, including Crypto Jumble.
                </p>
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  In order to acquire these rights, you will need to purchase a Passport NFT first.
                </p>
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  There are three levels of Passport NFTs, with different rights depending on the number of Passport NFTs you have.
                </p>
                <div className="cs-height_25 cs-height_lg_15"></div>
                <div className="cs-btn_group">
                </div>
              </div>
            </div>
            <div className="col-lg-5 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
            </div>
          </div>
        </div>
      </section>

      <div className="cs-height_75 cs-height_lg_45"></div>
      <section id="about">
        <div className="container">
          <div className="row wow fadeInLeft" data-wow-duration="1s" data-wow-delay="0.4s">
            <div className="col-xl-4 col-md-4 text-center jamOne">
              <img src={jamOne} className="w-200" />
              <img src={jamTitle1} className="h-60" style={{marginTop: '40px'}} />
            </div>
            <div className="col-xl-4 col-md-4 text-center jamThree">
              <img src={jamThree} className="w-200" />
              <img src={jamTitle3} className="h-60" style={{marginTop: '18px'}} />
            </div>
            <div className="col-xl-4 col-md-4 text-center jamSeven">
              <img src={jamSeven} className="w-200" />
              <img src={jamTitle7} className="h-60" style={{marginTop: '33px'}} />
            </div>
          </div>
        </div>
      </section>
      
      <div className="cs-height_75 cs-height_lg_45"></div>
      <section id="about">
        <div className="container">
          <div className="row align-items-center flex-column-reverse-lg">
            <div className="col-lg-12 wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s">
              <div className="cs-height_0 cs-height_lg_40"></div>
              <div className="cs-right_space_40">
                <div className="cs-seciton_heading cs-style1 text-uppercase">
                  <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">About the Future</h2>
                </div>
                <div className="cs-height_20 cs-height_lg_20"></div>
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  Starting your JAM DAO with the introduction of the lottery business!
                  After that, the businesses will be voted on from the blockchain-related categories.
                </p>
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  For each project, status holders and those holding special roles will be rewarded from the profits of each project.
                </p>
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  As a start-up company, we sincerely hope that you could introduce us to many of your friends and partners.
                </p>
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  JAM DAO aims to grow into a community of tens of thousands of participants over the next 5 years.
                </p>
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  When we reach the community of tens of thousands of members, we will move from a phase of community expansion to a phase of developing new business-focused DAO.
                </p>
              </div>

              <div className="cs-height_65 cs-height_lg_65"></div>
              <div className="cs-right_space_40">
                <div className="cs-seciton_heading cs-style1 text-uppercase">
                  <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">Terms</h2>
                </div>
                <div className="cs-height_45 cs-height_lg_45"></div>
                <div className="cs-seciton_heading cs-style1 text-uppercase">
                  <h2 className="cs-section_subtitle cs-m0 cs-font_36 cs-font_24_sm">Prohibition of Use</h2>
                </div>
                <div className="cs-height_20 cs-height_lg_20"></div>
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  By accessing and using JAMDAO services, you represent and warrant that
                  you have not been included in any trade embargoes or economic sanctions list.
                </p>
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  JAMDAO reserves the right to choose markets and jurisdictions to conduct business and
                  offer services and may restrict to refuse, at its discretion, the provision of JAMDAO services
                  in certain countries or regions.
                </p>
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  JAMDAO does not provide services to users in particular regions: the United States, North Korea,
                  Iran, China Mainland, Hong Kong Special Administrative Region, Japan, Korea, Ontario (Canada).
                </p>
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  Additionally, JAMDAO does not offer services in certain countries or regions restricted
                  by the U.S. Department of Treasury's Office of Foreign Assets Control (“OFAC”).
                </p>
                <p className="cs-font_24 cs-line-height_45 text-white font-bold">
                  The users from OFAC-sanctioned countries or regions are forbidden to use JAMDAOservices, Iran,
                  North Korea Sanctions, Ukraine, Russia, Cuba, Syria, etc.
                </p>
              </div>
            </div>
            <div className="col-lg-5 wow fadeInRight" data-wow-duration="1s" data-wow-delay="0.4s">
            </div>
          </div>
        </div>
      </section>

      <div className="cs-height_75 cs-height_lg_45"></div>
      <div className="cs-footer_wrap">
        <div className="container wow fadeIn" data-wow-duration="1s" data-wow-delay="0.2s" style={{maxWidth: '100%'}}>
          <div className="cs-cta cs-style1 cs-accent_bg text-center" style={{background: '#001d3c'}}>
            <div className="cs-footer_logo wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
              <img src={ZeroBlackLogo} className="cs-hide_dark" style={{ width: '100%',  maxWidth: '300px' }} />
              <img src={ZeroLogo} className="cs-hide_white" style={{ width: '100%', maxWidth: '300px'}} />
            </div>
            <div className="cs-height_10 cs-height_lg_10"></div>
            <div style={{textAlign:'right'}}>
              <a href={TWITTER_LINK} target="_blank" rel="noreferrer" style={{marginRight: '20px'}}>
                <img src={Icon_tw} className="social_icon mr-15" alt='icon tw'/>  
              </a>
              <a href={DISCORD_LINK} target="_blank" rel="noreferrer" style={{marginRight: '20px'}}>
                <img src={Icon_dc} className="social_icon" alt='icon dc'/>  
              </a>
            </div>
            <div className="cs-height_30 cs-height_lg_30"></div>
            {/* <div className="cs-cta_btns cs-center">
              <a href="https://discord.gg/YB5E5cQ4" className="cs-btn cs-btn_filed cs-white_btn_2" target="_blank">
                <span>About us</span>
              </a>
            </div> */}
            <div className="container" style={{marginBottom: '-69px', marginTop: '20px'}}>
              <div className="cs-copyright text-center wow fadeIn" data-wow-duration="1s" data-wow-delay="0.5s">Copyright©2023．All Rights Reserved&nbsp;&nbsp;<span className="cs-primary_font cs-primary_color">ADD TECHNOS LIMITED</span></div>
            </div>
          </div>
        </div>
        <footer className="cs-footer text-center">
        </footer>
      </div>

      <Modal open={isOpen} setIsOpen={setIsOpen} connectMetamask={connectMetamask} connectWalletConnect={connectWalletConnect} />
      <NotificationContainer />
    </div>
  )
}

export default Home;
